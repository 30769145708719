import { useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { languageModelsEnpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  LanguageModel,
  LanguageModels,
  ProviderName,
  Providers,
} from '@/modules/developerTools/types';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';

import { addTemporalToast } from '../../notifications/redux/actions';

export const API = Object.freeze({
  listLanguageModels: async (): Promise<LanguageModels> =>
    callGet(endpoints.languageModels),

  listProviders: async (): Promise<Providers> => callGet(endpoints.providers),

  createLanguageModel: async (
    newLanguageModel: Partial<LanguageModel>
  ): Promise<LanguageModel> =>
    callPost(endpoints.languageModels, newLanguageModel),

  getLanguageModel: async (languageModelId: string): Promise<LanguageModel> =>
    callGet(endpoints.languageModel(languageModelId)),

  updateLanguageModel: async (
    languageModelId: string,
    updatedLanguageModel: Partial<LanguageModel>
  ): Promise<LanguageModel> =>
    callPut(endpoints.languageModel(languageModelId), updatedLanguageModel),

  deleteLanguageModel: async (
    languageModelId: string
  ): Promise<LanguageModel> =>
    callDelete(endpoints.languageModel(languageModelId)),
});

export const onLanguageModelCreated = (
  queryClient: QueryClient,
  languageModel: LanguageModel
) => {
  queryClient.setQueryData<LanguageModel>(
    [endpoints.languageModel(languageModel.language_model_id)],
    (prev: LanguageModel) => ({ ...prev, ...languageModel })
  );

  const queryKey = [endpoints.languageModels, languageModel.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<LanguageModels>(queryKey, (prev) => ({
      language_models: [
        ...(prev?.language_models || []).filter(
          (acc) => acc.language_model_id !== languageModel.language_model_id
        ),
        languageModel,
      ],
    }));
  }
};

export const onLanguageModelDelete = (
  queryClient: QueryClient,
  account_id: string,
  language_model_id: string
) => {
  const queryKey = [endpoints.languageModels, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<LanguageModels>(
      queryKey,
      (prev: LanguageModels) => ({
        language_models: (prev?.language_models || []).filter(
          (acc) => acc.language_model_id !== language_model_id
        ),
      })
    );
  }
  queryClient.removeQueries({
    queryKey: [endpoints.languageModel(language_model_id)],
  });
};

export const onLanguageModelUpdated = (
  queryClient: QueryClient,
  languageModel: LanguageModel
) => {
  const queryKey = [endpoints.languageModel(languageModel.language_model_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<LanguageModel>(queryKey, (prev) => ({
      ...prev,
      ...languageModel,
    }));
  }

  // update all
  const modelsQueryKey = [endpoints.languageModels, languageModel.account_id];
  if (queryClient.getQueryData(modelsQueryKey)) {
    queryClient.setQueryData<LanguageModels>(modelsQueryKey, (prev) => ({
      ...prev,
      language_models: prev.language_models.map((m) =>
        m.language_model_id === languageModel.language_model_id
          ? languageModel
          : m
      ),
    }));
  }
};

const useLanguageModels = (languageModelId?: string) => {
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { brains } = useBrains();
  const canReadLanguageModels = useSelector((state: RootState) =>
    getPermissions(state, 'language_models', actions.READ)
  );

  const { data: providers, status: providersStatus } = useQuery<
    Providers,
    Error
  >({
    queryKey: [endpoints.providers],
    queryFn: () => API.listProviders(),
    enabled: !!accountId,
  });

  const { data: languageModels, status: listStatus } = useQuery<
    LanguageModels,
    Error
  >({
    queryKey: [endpoints.languageModels, accountId],
    queryFn: () => API.listLanguageModels(),
    enabled: !!accountId && canReadLanguageModels,
  });

  const { mutate: createLanguageModel, status: createStatus } = useMutation<
    LanguageModel,
    Error,
    Partial<LanguageModel>
  >({
    mutationFn: (newLanguageModel: Partial<LanguageModel>) =>
      API.createLanguageModel(newLanguageModel),
    onSuccess: (resp) => {
      onLanguageModelCreated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t(`brains.llms.created`, { 0: resp.model }))
      );
    },
  });

  const { data: languageModel, status: getStatus } = useQuery<
    LanguageModel,
    Error
  >({
    queryKey: [endpoints.languageModel(languageModelId)],
    queryFn: () => API.getLanguageModel(languageModelId),
    enabled: !!languageModelId,
  });

  // Update mutation
  const { mutate: updateLanguageModel, status: updateStatus } = useMutation<
    LanguageModel,
    Error,
    Partial<LanguageModel>
  >({
    mutationFn: (m) => API.updateLanguageModel(languageModelId, m),

    onSuccess: (resp) => {
      onLanguageModelUpdated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t(`brains.llms.updated`, { 0: resp.model }))
      );
    },
  });

  const { mutate: deleteLanguageModel, status: deleteStatus } = useMutation<
    LanguageModel,
    Error,
    string
  >({
    mutationFn: (languageModelId) => API.deleteLanguageModel(languageModelId),
    onSuccess: (resp) => {
      onLanguageModelDelete(queryClient, accountId, resp.language_model_id);
      dispatch(addTemporalToast('success', t(`brains.llms.deleted`)));
    },
  });

  // Get all brains that use the language model
  const languageModelBrains = useMemo(
    () =>
      brains?.filter((brain) => brain.language_model_id === languageModelId),
    [brains, languageModelId]
  );

  // Returns providers having models not created yet
  const uncreatedProviders = useMemo(() => {
    if (!languageModels) {
      return [];
    }

    const createdModels = new Set(
      (languageModels?.language_models || []).map(
        ({ provider, model }) => `${provider}:${model}`
      )
    );

    return Object.keys(providers || {}).filter(
      (provider) =>
        provider !== 'moveo' &&
        providers[provider].models.some(
          (model) => !createdModels.has(`${provider}:${model.name}`)
        )
    ) as ProviderName[];
  }, [languageModels, providers]);

  const enabledModelsWithMoveo = [
    ...(languageModels?.language_models.filter((m) => m.enabled) || []),
    {
      provider: 'moveo',
      language_model_id: 'Default',
      model: 'Default',
      enabled: true,
    },
  ];

  return {
    languageModels: languageModels?.language_models || [],
    listStatus,
    enabledModels:
      languageModels?.language_models.filter((m) => m.enabled) || [],
    enabledModelsWithMoveo,
    createLanguageModel,
    createStatus,
    languageModel,
    getStatus,
    updateLanguageModel,
    updateStatus,
    deleteLanguageModel,
    deleteStatus,
    providers,
    providersStatus,
    uncreatedProviders,
    languageModelBrains,
  };
};

export default useLanguageModels;
