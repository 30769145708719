import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkmark from '@/components/atoms/Icons/Checkmark';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { AdvancedSettings } from './AdvancedSettings';
import { CompanyName } from './CompanyName';
import { GoalForm } from './GoalForm';
import { ResponseLengthForm } from './ResponseLengthForm';
import { ToneOfVoiceForm } from './ToneOfVoiceForm';
import { getResponseLength } from '../../helper';
import { Badge } from '../Badge/Badge';
import { NumberIcon } from '../NumberIcon/NumberIcon';
import { Summary } from '../Summary/Summary';

export const SellerSetup = ({
  isDebtCollection = false,
  showAdvancedSettings = false,
  onToggle = () => {},
}) => {
  return (
    <>
      <CompanyName number={1} />
      <GoalForm number={2} />
      <ToneOfVoiceForm number={3} isDebtCollection={isDebtCollection} />
      <ResponseLengthForm number={4} />
      <AdvancedSettings
        show={showAdvancedSettings}
        number={5}
        onToggle={onToggle}
      />
    </>
  );
};

export const SellerSummary = ({
  isDebtCollection = false,
  showAdvancedSettings = false,
}) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { guidelines } = brain || {};
  const hasGoal = !!guidelines?.goal;
  const hasToneOfVoice = typeof guidelines?.tone_of_voice === 'number';
  const hasResponseLength = !!guidelines?.response_size;
  const hasCompanyName = !!guidelines?.name;

  const getCreativityLevel = (number: number) => {
    switch (number) {
      case -1:
        return isDebtCollection
          ? t('ai_agents.tone_of_voice.creative')
          : t('ai_agents.tone_of_voice.empathetic');
      case 0:
        return t('ai_agents.tone_of_voice.neutral');
      case 1:
        return isDebtCollection
          ? t('ai_agents.tone_of_voice.firm')
          : t('ai_agents.tone_of_voice.matter_of_fact');
      case 2:
        return isDebtCollection
          ? t('ai_agents.tone_of_voice.urgent')
          : t('ai_agents.tone_of_voice.professional');
    }
  };

  return (
    <Summary>
      <Summary.Step
        text={t('ai_agents.company_name.title')}
        icon={hasCompanyName ? <Checkmark /> : <NumberIcon number={1} />}
        onClick={() => scrollToElementById('ai-agent-company-name')}
      />
      <Summary.Step
        text={t('ai_agents.goal.title')}
        icon={hasGoal ? <Checkmark /> : <NumberIcon number={2} />}
        onClick={() => scrollToElementById('ai-agent-goal')}
      />

      <Summary.Step
        text={t('ai_agents.tone_of_voice.title')}
        icon={hasToneOfVoice ? <Checkmark /> : <NumberIcon number={3} />}
        onClick={() => scrollToElementById('ai-agent-tone-of-voice')}
      >
        {hasToneOfVoice && (
          <Badge
            ml="var(--space-4)"
            badgeVariant="blue"
            text={getCreativityLevel(guidelines.tone_of_voice)}
          />
        )}
      </Summary.Step>

      <Summary.Step
        text={t('ai_agents.response_length.title')}
        icon={hasResponseLength ? <Checkmark /> : <NumberIcon number={4} />}
        onClick={() => scrollToElementById('ai-agent-response-length')}
      >
        {hasResponseLength && (
          <Badge
            ml="var(--space-4)"
            badgeVariant="blue"
            text={getResponseLength(guidelines.response_size)}
          />
        )}
      </Summary.Step>
      {showAdvancedSettings && (
        <Summary.Step
          text={t('ai_agents.advanced_settings')}
          icon={<NumberIcon number={5} />}
          onClick={() => scrollToElementById('ai-agent-confidence-threshold')}
        />
      )}
    </Summary>
  );
};
